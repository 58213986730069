// src/api/forwardToBackend.js
import axiosInstance from './axiosInstance';

// Function to handle POST requests
export async function postDataToBackend(endpoint, data) {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data to backend:', error);
    throw error;
  }
}

// Function to handle GET requests
export async function fetchDataFromBackend(endpoint) {
  try {
    const response = await axiosInstance.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data from backend:', error);
    throw error;
  }
}
