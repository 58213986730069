import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LoadingAnimation from './LoadingAnimation';
import sendSVG from './sendSVG.svg';
import { fetchDataFromBackend, postDataToBackend } from './api/forwardToBackend';

function App() {
  const [startTime] = useState(Date.now());
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [conversationId, setConversationId] = useState(''); 
  const [agentName, setAgentName] = useState('Agente Disponible'); 
  const [firstMessage, setFirstMessage] = useState('¡Hola! Cuéntame, ¿para que auto deseas cotizar un seguro?'); 
  const [loaded1, setLoaded1] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [isTypingVisible, setIsTypingVisible] = useState(false);
  const [parentPage, setParentPage] = useState("");
  const [parentPageURL, setParentPageURL] = useState("");
  const [loadTime, setLoadTime] = useState(0);
  const bottomRef = useRef(null);
  const scrollContainerRef = useRef(null); // New ref for the scroll container

  const firstMessageRef = useRef(firstMessage);

  useEffect(() => {
    firstMessageRef.current = firstMessage;
  }, [firstMessage]);

  useEffect(() => {
    const handleMessage = (event) => {
      const allowedOrigins = ["https://seguros-ert.mx", "https://kayum.mx"];
      if (allowedOrigins.includes(event.origin)) {
        console.log(`Message received from allowed origin ${event.data.origin}:`, event);
        console.log(`Message full url ${event.data.fullUrl}:`, event);
        setParentPage(event.data.origin);
        setParentPageURL(event.data.fullUrl);
      }
    };
  
    window.addEventListener("message", handleMessage);
  
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const fetchData = async (loadDuration) => {
      const payload = { loadTime: loadDuration, convId: conversationId, origin: parentPage, url: parentPageURL };
      try {
        await postDataToBackend('/time', payload);
      } catch (error) {
        console.error('Error calling API:', error);
      }
    };
    if (loadTime !== 0 && parentPage !== "" && parentPageURL !== "") {
      fetchData(loadTime);
    }
  }, [conversationId, parentPage, parentPageURL, loadTime]);

  useEffect(() => {
    
    if (loaded1 && loaded2) {
      const loadDuration = Date.now() - startTime;
      setLoadTime(loadDuration)
    }
  }, [loaded1, loaded2, startTime]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Adjust the endpoint path as needed for your config endpoint
        const result = await fetchDataFromBackend('/getConfig');
        setAgentName(result.agentName);
        setFirstMessage(result.firstMessage);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoaded1(true);
      }
    };
  
    fetchData();
  }, []);

  const reloadChat = async (id) => {
    setIsTypingVisible(false);
    const payload = { convId: id };
  
    try {
      // Make the POST request using postDataToBackend
      const data = await postDataToBackend('/getChat', payload);
  
      if (data.status === 'success') {
        setMessages(
          data.messages.length > 2 ? data.messages : [{ role: 'assistant', content: firstMessageRef.current }]
        );
      } else {
        console.error('API response error:', data);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  useEffect(() => {
    const existingId = localStorage.getItem('conversationId');
    if (existingId) {
      setConversationId(existingId);
      reloadChat(existingId);
    } else {
      const newId = uuidv4();
      localStorage.setItem('conversationId', newId);
      setConversationId(newId);
      reloadChat(newId);
    }
    setLoaded2(true);
  }, [setLoaded2]);

  const handleSendMessage = async () => {
    if (input.trim() === '/clear') {
      const newId = uuidv4();
      localStorage.setItem('conversationId', newId);
      setConversationId(newId);
      reloadChat(newId);
      setInput(''); 
      return;
    }
    if (input.trim() === '/internal') {
      const payload = { tag: "internal", convId: conversationId }; 
      await postDataToBackend('/tag', payload);
      setInput(''); 
      return;
    }
    if (input.trim() !== '' && input.length <= 300 && messages.length <= 110) {
      const newMessages = [...messages, { content: input, role: 'user' }];
      setMessages(newMessages);
      setInput(''); 
      setTimeout(() => setIsTypingVisible(true), 500); 
      await callApi(newMessages);
      setIsTypingVisible(false);
    }
  };

  const checkMessagesAndSendConversion = (messages) => {
    if (messages.length >= 2) {
      const lastMessage = messages[messages.length - 1];
      const secondLastMessage = messages[messages.length - 2];
      if (
        lastMessage.role === 'assistant' &&
        secondLastMessage.role === 'system' &&
        lastMessage.content.length !== secondLastMessage.content.length
      ) {
        console.log("Conversion sent")
        window.gtag('event', 'conversion', {
          send_to: 'AW-16766888739/vut3COnDheYZEKPWibs-',
        });
      }
    }
  };

  const callApi = async (conversation) => {
    const payload = { messages: conversation, convId: conversationId };
  
    try {
      const data = await postDataToBackend('/chat', payload);
  
      if (data.status === 'success') {
        setMessages(data.messages);
        checkMessagesAndSendConversion(data.messages)
      } else {
        console.error('API response error:', data);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value.length <= 300) {
      setInput(e.target.value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  function renderLinks(text) {
    // Regex to match both [Link Text](URL) and [Link Text]{URL} formats
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)|\[([^\]]+)\]\{([^}]+)\}/g;
  
    const payload = { tag: "WA", convId: conversationId }; 
  
    const lines = text.split('\n').map((line, index) => {
      const parts = [];
      let lastIndex = 0;
  
      line.replace(linkRegex, (match, textContent, linkParenthesis, linkCurlyText, linkCurly, offset) => {
        // Determine the link text and URL based on which format matched
        const linkText = textContent || linkCurlyText;
        const link = linkParenthesis || linkCurly;
  
        // Add the text before the link
        if (offset > lastIndex) {
          parts.push(line.slice(lastIndex, offset));
        }
  
        // Add the link with conditional onClick for WhatsApp
        parts.push(
          <a
            key={`${index}-${offset}`}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
            onClick={() => {
              if (link.includes('whatsapp.com')) {
                // Call the API without preventing the default action
                postDataToBackend('/tag', payload);
              }
            }}
          >
            {linkText}
          </a>
        );
        lastIndex = offset + match.length;
      });
  
      // Add any remaining text after the last link
      if (lastIndex < line.length) {
        parts.push(line.slice(lastIndex));
      }
  
      return (
        <span key={index}>
          {parts}
          <br /> {/* Add a line break */}
        </span>
      );
    });
  
    return lines;
  }
  

  useEffect(() => {
    if ((loaded1 && loaded2) || isTypingVisible) {
      bottomRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [messages, loaded1, loaded2, isTypingVisible]);

  return (
    loaded1 && loaded2 ? (
      <div className="flex flex-col h-screen items-center justify-top bg-transparent">
        <div className="w-full max-w-md bg-white shadow-xl rounded-xl overflow-hidden flex flex-col" style={{ height: `calc(${messages.length <= 1 ? '70vh' : '95vh'})` }}>
          <div style={{ backgroundColor: '#4790CF' }} className="text-white p-4 text-lg font-semibold flex items-center space-x-3">
            <div className="h-6 w-6 mr-4 rounded-full bg-green-500 border-2 border-white"></div>
            {agentName}
          </div>
          
          <div className="flex-1 p-4 overflow-y-auto bg-gray-50" ref={scrollContainerRef} style={{ maxHeight: '80vh' }}>
            <div className="flex flex-col space-y-4">
              {messages.length === 0 && (
                <div className="text-gray-400 text-center">
                  ¡Hola! ¿En qué puedo ayudarte?
                </div>
              )}
              {messages
                .filter((message) => message.role !== 'system')
                .map((message, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded-lg max-w-xs break-words ${
                      message.role === 'user'
                        ? 'text-white self-end' 
                        : 'bg-gray-300 text-black self-start'
                    }`}
                    style={message.role === 'user' ? { backgroundColor: '#4790CF' } : {}}
                  >
                    {message.role === 'assistant'
                      ? renderLinks(message.content) // Usa renderLinks para mensajes del asistente
                      : message.content.split('\n').map((line, idx) => (
                          <span key={idx}>
                            {line}
                            <br />
                          </span>
                        ))
                    }
                  </div>
                ))}
              {isTypingVisible && (
                <div className="bg-gray-300 text-black p-3 rounded-lg max-w-xs self-start flex items-center space-x-1 mt-2">
                  <span className="bg-gray-400 rounded-full w-2.5 h-2.5 animate-bounce"></span>
                  <span className="bg-gray-400 rounded-full w-2.5 h-2.5 animate-bounce animation-delay-200"></span>
                  <span className="bg-gray-400 rounded-full w-2.5 h-2.5 animate-bounce animation-delay-400"></span>
                </div>
              )}
              <div ref={bottomRef} />
            </div>
          </div>

          <div className="bg-gray-200 p-4 flex">
            <input
              type="text"
              className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Escribe un mensaje..."
            />
            <button
              onClick={handleSendMessage}
              className="ml-4 p-2 rounded-full hover:bg-blue-500 focus:outline-none"
              style={{ backgroundColor: '#4790CF', width: '40px', height: '40px' }}
            >
              <img src={sendSVG} alt="Send" className="w-full h-full" />
            </button>
          </div>
        </div>
      </div>
    ) : (
      <LoadingAnimation />
    )
  );
}

export default App;
