// src/api/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/api', // Relative URL, assumes backend is accessible at `/api`
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
