import React, { useState, useEffect } from "react";

const LoadingAnimation = () => {
    const [count, setCount] = useState(5);
    const [currentSegments, setCurrentSegments] = useState(0);
    const [isPaused, setIsPaused] = useState(false); // State to track the 3-second pause

    // StrokeDasharray values for segments1, segments2, segments3, and segments4
    const strokeDasharray1 = "10 5 90 5 30 5 150 5 50 5";
    const strokeDasharray2 = "30 5 60 5 45 5 85 5 115 5";
    const strokeDasharray3 = "20 5 65 5 45 5 85 5 100 5";
    const strokeDasharray4 = "25 5 70 5 50 5 45 5 105 5";

    // Use radius for the circular segments
    const radius = 75;

    useEffect(() => {
        if (count > 0 && !isPaused) {
            const timer = setTimeout(() => setCount(count - 1), 1000);
            return () => clearTimeout(timer);
        } else if (count === 0 && !isPaused) {
            // When count reaches 0, start the 3-second pause
            setIsPaused(true);
            setTimeout(() => {
                setIsPaused(false); // End pause after 3 seconds
                setCount(5); // Reset the count to 5
            }, 3000);
        }
    }, [count, isPaused]);

    // Toggle between segment configurations every second
    useEffect(() => {
        if (!isPaused) { // Only update segments when not paused
            const toggleTimer = setInterval(() => {
                setCurrentSegments((prev) => (prev + 1) % 4); // Alternate between 0, 1, 2, and 3
            }, 1000); // 1-second interval

            return () => clearInterval(toggleTimer); // Clean up timer on component unmount
        }
    }, [isPaused]);

    // Choose the current strokeDasharray based on the segment state
    const strokeDasharray = 
        currentSegments === 0 ? strokeDasharray1 :
        currentSegments === 1 ? strokeDasharray2 :
        currentSegments === 2 ? strokeDasharray3 : 
        strokeDasharray4;

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-transparent">
            <div className="relative flex items-center justify-center w-60 h-60 mb-0">
                {/* Add rotation animation class during the 3-second pause */}
                <svg
                    width="180"
                    height="180"
                    viewBox="-90 -90 180 180"
                    className={isPaused ? "animate-rotation" : ""}
                >
                    {/* Define the gradient */}
                    <defs>
                        <linearGradient id="blueToRedGradient" x1="0%" y1="100%" x2="100%" y2="0%">
                            <stop offset="0%" style={{ stopColor: "#0D3094", stopOpacity: 1 }} />
                            <stop offset="100%" style={{ stopColor: "#2699FB", stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>

                    <circle
                        cx="0"
                        cy="0"
                        r={radius}
                        fill="transparent"
                        stroke="url(#blueToRedGradient)" // Use gradient for the stroke
                        strokeWidth="14"
                        strokeDasharray={strokeDasharray}
                        style={{
                            transition: isPaused ? "none" : "stroke-dasharray 0.5s ease",
                        }}
                    />
                </svg>

                <div className="absolute w-36 h-36 bg-transparent rounded-full"></div>

                {/* Countdown Text - Hidden when paused */}
                {!isPaused && (
                    <div className="absolute text-center">
                        <div
                            className="text-4xl font-bold"
                            style={{
                                background: "linear-gradient(45deg, #0D3094, #2699FB)",
                                WebkitBackgroundClip: "text",
                                color: "transparent",
                            }}
                        >
                            {count}
                        </div>
                        <div className="text-sm text-[#0D3094]">segundos</div>
                        <div className="text-sm text-[#0D3094]">Espera estimada</div>
                    </div>
                )}
            </div>

            {/* Bottom Text */}
            <p className="text-[#0D3094] text-lg mt-0">
                {isPaused ? "Estableciendo Conexion" : "Contactando agente"}
            </p>

            {/* Add rotation animation style */}
            <style jsx>{`
                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                .animate-rotation {
                    animation: rotation 3s linear infinite;
                }
            `}</style>
        </div>
    );
};

export default LoadingAnimation;
